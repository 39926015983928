import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Badge from "../../components/badge";

const EdgePage = () => (
	<Layout>
		<SEO title="Edge"/>
		<div className="overflow-x-hidden">
			<div className="edge bg-edge">
				<div className="align-baseline fit-down force site-padding-vertical">
					<h1>Edge</h1>
					<p className="text-preset-large-intro max-char-45">With <dfn>edge</dfn> you define the <dfn>inner width</dfn> of the website.</p>
					<p>By default <dfn>edge</dfn> has a padding of 2 space units to create spacing between your elements and the edge of the website.
						This
						is in case the viewport width equals the width of the website. This is called the <dfn>outer width</dfn>.</p>

					<div className="snap-edge">
						<div className="edge bg-edge">
							<div className="bg-black text-white block-margin-vertical">
								Example-1: this example has the width of the <dfn>inner width</dfn> of the edge/website
							</div>
						</div>
					</div>

					<h2>Snap edge <Badge>1.3+</Badge></h2>
					<p className="text-preset-medium-intro">With <dfn>snap edge</dfn> you can snap to the <dfn>outer width</dfn> of the edge.</p>
					<p> Using <dfn>snap edge</dfn> is handy when you need a image or background to be full-width for example on a mobile or tablet
						viewport.</p>
					<div className="snap-edge">
						<div className="bg-black text-white block-margin-vertical">
							<div className="edge">Example 1: has <dfn>edge</dfn> inside <dfn>snap edge</dfn></div>
						</div>
						<div className="bg-black text-white block-margin-vertical">Example 2: basic</div>
					</div>

					<h2>Breakout edge <Badge>Experimental</Badge> <Badge>1.3+</Badge></h2>
					<p className="text-preset-medium-intro"><dfn>Breakout edge</dfn> can be used to breakout of the edge to the full width of the
						website</p>

					<p><strong>Warning:</strong> this technique is highly experimental. The technique uses <code>100vw</code> for its calculations,
						this causes a horizontal scrollbar on computer with a default mouse. The scrollbar can be removed by
						using <code>overflow-x:hidden;</code> on a div wrapped around the <dfn>edge</dfn>.</p>
					<p>You can use <dfn>breakout edge</dfn> safely when the viewport width is small then the size of the outer width of the edge.</p>

					<div className="breakout-edge">
						<div className="bg-black text-white block-margin-vertical">
							<div className="edge">
								Example 1: Edge inside a breakout element
							</div>
						</div>
					</div>

					<div className="breakout-edge">
						<div className="bg-black text-white block-margin-vertical">Example 2: This example might be slightly broken depending on your
							type of mouse. Causing a negative indent of several pixels.
						</div>
					</div>

					<h3>Roadmap for <dfn>breakout edge</dfn></h3>
					<ul>
						<li>Create javascript polyfill to create an alternative <code>--vw</code> variable</li>
					</ul>

				</div>
			</div>
		</div>
	</Layout>
)

export default EdgePage
